import * as React from "react"
import {AdminLayout} from 'widgets'
// markup
const IndexPage = () => {
  return (
    <AdminLayout>
      <div className="home">
        <p>
          dashboard
        </p>
      </div>
    </AdminLayout>
  )
}

export default IndexPage
